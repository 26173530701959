export default {
  state: {
    main:
      [
        {
          title:{
            kz:"Құрметті ата-аналар!",
            ru:"Уважаемые родители!",
            en:"Dear parents!"
          },
          description:{
            kz:"<span>Зияткерлік мектептер 10 жылдан астам уақыт бойы қазіргі заман талабына сай құзіреттілігі жоғары түлектерді дайындап келеді.</span><br/>" +
                "<span>Назарбаев Зияткерлік мектептерінде білім беру NIS-бағдарламасы және халықаралық бакалавриат бағдарламалары бойынша жүргізіледі.</span><br/>" +
                "<span>Үздік қазақстандық тәжірибе мен халықаралық тәжірибені біріктіретін NIS-Programme білім беру бағдарламасы жаратылыстану-математикалық пәндерді тереңдетіп оқытуға бағытталған. NIS-бағдарламасы халықаралық деңгейде мойындалды, сондықтан 2017 жылы Ұлыбританияның NARIC білім беру сертификаттарының ұлттық ақпараттық тану орталығы Австралиядағы, Жаңа Зеландиядағы көптеген жоғары оқу орындары мойындаған GCE A-деңгейімен NIS-бағдарламасының мазмұнының салыстырмалылығын растады. , Қытай, Гонконг, Жапония, Еуропалық Одақ, Канада, АҚШ.</span><br/>" +
                "<span>GCE A-Level бағдарламасының түлектері жоғары оқу орындарына қосымша түсу емтихандарынсыз және Foundation университетіне дейінгі дайындық курсынан өтпей түсуге мүмкіндік алады.</span><br/>" +
                "<span>Зияткерлік мектептердегі оқу нәтижелерін қазақстандық университеттер де мойындайды. Қазіргі уақытта Зияткерлік мектептердің түлектері Қазақстанның жетекші жоғары оқу орындарына бірден екінші курсқа түсуге мүмкіндік алды.</span><br/>" +
                "<span>Назарбаев Зияткерлік мектептерінің ұжымы әр оқушыға қамқорлық пен ілтипатпен қарай отырып, оқыту мен тәрбиелеудің әдіс-тәсілдері мен технологияларын жетілдіру жолында үздіксіз жұмыс жасауда.</span><br/>" +
                "<span>Біз оқу процесі тек ақпаратты және қызықты ғана емес, сонымен қатар ыңғайлы және қауіпсіз болуы керек деп шын жүректен сенеміз. Бұл орайда біз ұстаздармен бірге сіздерге – балаларымыздың ата-аналарына үлкен рөл жүктейміз.</span><br/>" +
                "<span>Сізден ішкі ережелермен, саясаттармен, сондай-ақ білім беру қызметтерін көрсету шартымен танысуыңызды, ата-аналар жиналысына және ата-аналар үшін арнайы әзірленген барлық вебинарларға, семинарларға және оқыту бағдарламаларына белсенді қатысуыңызды сұраймыз. Осының барлығы біздің балаларымыздың мектепте оқуын одан да бақытты әрі гүлдендіріп отыруына көмектеседі деп сенеміз!</span><br/>" +
                "<span>Назарбаев Зияткерлік мектептері оқушыларының ата-аналар қоғамдастығына қош келдіңіздер, тату әрі үлкен отбасымызға қош келдіңіздер!</span><br/>",
            ru:"<span>На протяжении более 10 лет Интеллектуальные школы готовят выпускников с высоким уровнем компетенций, отвечающих требованиям современного мира.</span><br/>" +
                "<span>В Назарбаев Интеллектуальных школах обучение ведется по программам NIS-Programme и международного бакалавриата.</span><br/>" +
                "<span>Образовательная программа NIS-Programme, сочетающая в себе лучший казахстанский опыт и международную практику, ориентирована на углубленное изучение естественно-математических дисциплин. NIS-Programme признана на международном уровне, так в 2017 году Национальным информационным центром признания свидетельств об образовании Великобритании UK NARIC подтверждена сопоставимость содержания NIS-Programme с уровнем GCE A-Level, признаваемым многими высшими учебными заведениями Австралии, Новой Зеландии, Китая, Гонконга, Японии, Европейского союза, Канады, США.</span><br/>" +
                "<span>Выпускники программы GCE A-Level имеют возможность поступать в высшие учебные заведения без дополнительных вступительных экзаменов и прохождения курса предуниверситетской подготовки Foundation.</span><br/>" +
                "<span>Результаты обучения в Интеллектуальных школах также признаются казахстанскими вузами. На данный момент выпускники Интеллектуальных школ имеют возможность поступления сразу на второй курс в ведущие вузы Казахстана.</span><br/>" +
                "<span>Команда Назарбаев Интеллектуальных школ постоянно работает над совершенствованием методики и технологии обучения и воспитания, проявляя заботу и внимание к каждому ученику.</span><br/>" +
                "<span>Мы искренне считаем, что учебный процесс должен быть не только познавательным и интересным, но и комфортным и безопасным. В этом мы отводим значительную роль, наряду с педагогами, Вам – родителям наших детей.</span><br/>" +
                "<span>Мы просим Вас ознакомиться с внутренними правилами, политиками, а также с договором на оказание образовательных услуг, активно участвовать на родительских собраниях и всех вебинарах, семинарах и обучающих программах, разработанных специально для родителей. Мы верим, что все это поможет сделать пребывание наших детей в школе еще более частливым и благополучным!</span><br/>" +
                "<span>Добро пожаловать в сообщество родителей учащихся Назарбаев Интеллектуальных школ, добро пожаловать в нашу дружную и большую семью!</span><br/>",
            en:"<span>For more than 10 years, the Intellectual Schools have been preparing graduates with a high level of competencies that meet the requirements of the modern world.</span><br/>" +
                "<span>In Nazarbayev Intellectual Schools, education is conducted according to the NIS-Programme and International Baccalaureate programs.</span><br/>" +
                "<span>The educational program NIS-Programme, which combines the best Kazakhstani experience and international practice, is focused on in-depth study of natural and mathematical disciplines. The NIS-Programme is recognized internationally, so in 2017, the National Information Recognition Center for Educational Certificates UK NARIC confirmed the comparability of the content of the NIS-Programme with the GCE A-Level recognized by many higher education institutions in Australia, New Zealand, China, Hong Kong, Japan , European Union, Canada, USA.</span><br/>" +
                "<span>Graduates of the GCE A-Level program have the opportunity to enter higher education institutions without additional entrance exams and passing the Foundation pre-university preparation course.</span><br/>" +
                "<span>Learning outcomes at the Intellectual Schools are also recognized by Kazakhstani universities. At the moment, graduates of the Intellectual Schools have the opportunity to immediately enter the second year in the leading universities of Kazakhstan.</span><br/>" +
                "<span>The team of Nazarbayev Intellectual Schools is constantly working on improving the methods and technologies of teaching and education, showing care and attention to each student.</span><br/>" +
                "<span>We sincerely believe that the educational process should be not only informative and interesting, but also comfortable and safe. In this, we assign a significant role, along with teachers, to you - the parents of our children.</span><br/>" +
                "<span>We ask you to familiarize yourself with the internal rules, policies, as well as the contract for the provision of educational services, actively participate in parent-teacher meetings and all webinars, seminars and training programs designed specifically for parents. We believe that all this will help make our children's stay at school even more happy and prosperous!</span><br/>" +
                "<span>Welcome to the community of parents of students of Nazarbayev Intellectual Schools, welcome to our friendly and big family!</span><br/>",
          },
          pic:"parents/parents_top.jpg"
        },
        {
          title:{
            kz:"Ата-аналар университеті",
            ru:"Родительский Университет",
            en:"Parent’s University"
          },
          description:{
            kz:"«Ата-аналар университеті» – отбасын нығайтуға, отбасы мен әлеуметтік тәрбиенің байланысына мүдделі ата-аналар қоғамдастығының өкілдерін, әкімшілік және педагогикалық қызметкерлерді біріктіретін және тәрбие мәселелерін тиімді шешуге құзыреттілігін дамытуға бағытталған білім беру жобасы. , Зияткерлік мектеп оқушыларын дамыту және тәрбиелеу. Ата-ананың санасын дамыту және ақпараттандыру, ойлау мен мінез-құлықты өзгерту, балалармен қарым-қатынас дағдыларын дамыту арқылы жауапты ата-аналық ұстаным мен оң ата-ана болу дағдыларын қалыптастыру университеттің мақсаты болып табылады. Ата-аналар университеті студенттердің ата-аналары, педагог-ұйымдастырушы кураторлар, мектеп психологтары мен жатақхана тәрбиешілерінің бірлескен күш-жігерімен сәтті жүзеге асырылуда.",
            ru:"«Родительский Университет» - это образовательный проект, объединяющий представителей родительской общественности, административных и педагогических работников, заинтересованных в укреплении семьи, связи семейного и общественного воспитания, и  направленный на формирование компетенций, позволяющих эффективно решать вопросы воспитания, развития и образования учащихся Интеллектуальной школы. Развитие родительской осознанности и формирование навыков ответственной родительской позиции и позитивного родительства через информирование, изменение мышления и поведения, выработку навыков общения с детьми являются целью университета. Родительский университет успешно реализуется совместными усилиями родителей учащихся, педагог-организатор кураторов, школьных психологов и воспитателей общежития.",
            en:"\"Parents’ University\" is an educational project that unites representatives of the parent community, administrative and pedagogical workers who are interested in strengthening the family, the connection of family and social education. It is aimed at the formation of competencies that allow to solve the issues of effective upbringing, development and education of Intellectual school students. The goal of the project is to develop parental awareness and the formation of skills of responsible and positive parenting through changing thinking and behavior, informing and developing communication skills with children. The project is successfully realised by the joint efforts of parents of students, curators, school psychologists and dormitory educators.",
          },
          pic:"parents/parent_univer.jpg"
        },
      ]
  },
  getters: {
    getMain (state) {
      return state.main
    }
  },
  mutations: {

  },
  actions: {

  },
  namespaced: true
}
