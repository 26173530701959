export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salem"])},
  "coming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЖАҚЫНДА ШЫҒАМЫЗ"])},
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіру"])},
  "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығу"])},
  "cabinet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жеке бөлім"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Байланыс"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шымкент қаласы, м/а Ақжайық, 6 - ғимарат"])},
  "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бізге жазыңыздар"])},
  "read-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көбірек білу...."])},
  "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брондау"])},
  "video-trans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конкурстық іріктеудің бейнетрансляциясы"])},
  "corp_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мектептің корпоративтік поштасы"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия сөз"])},
  "news": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңалықтар"])},
    "last-news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соңғы жаңалықтар"])}
  },
  "main-page": {
    "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мектеп миссиясы"])},
    "mission-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заманауи білім дағдыларын меңгерген Зияткер, адамгершілігі мол, салауатты әлем азаматын тәрбиелеу."])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мектеп туралы"])},
    "best-school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіздің балаларыңыз үшін ең жақсы таңдау"])},
    "about-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шымкент қаласындағы физика-математика бағытындағы Назарбаев Зияткерлік мектебі 2013 жылдың 16 ақпанында ашылды. Салтанатты шараға Оңтүстік Қазақыстан обылысының әкімі Асқар Мырзахметов қатысты. Қазақыстанның ең ірі өңірлерінің біріндегі жаңа мектеп Назарбаев Зияткерлік мектептері отбасындағы тоғызыншы болды және білім берудің сапалы өсуін қамтамасыз етуге, оқу үдерісіне иновациаларды енгізуге, мұғалімдердің біліктілігін арттыруға арналған персонал. Ол үшін барлық жағдай жасалған."])},
    "almuni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТҮЛЕКТЕР"])},
    "foreign-graduate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ШЕТЕЛ УНИВЕРСИТЕТІНЕ ТҮСКЕНДЕР"])},
    "local-graduate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ҚАЗАҚСТАН УНИВЕРСИТЕТІНЕ ТҮСКЕНДЕР"])},
    "nu-graduate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НАЗАРБАЕВ УНИВЕРСИТЕТІНЕ ТҮСКЕНДЕР"])},
    "meet-teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біздің мұғалімдермен танысыңыз"])}
  },
  "document": {
    "regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ережелер"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүкту күні"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекет"])}
  },
  "teachers": {
    "best": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үздік жаттықтырушылар"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық жаттықтырушылар"])},
    "trainers-cert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сертификатталған жаттықтырушылар"])},
    "trainers-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаттықтырушылар тізімі"])}
  },
  "live": {
    "fest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VII Шығармашылық идеялар фестивалі"])},
    "creative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Креативті самғау"])},
    "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өтініштерді тіркеу аяқталу уақыты"])},
    "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оқиғалар"])},
    "last-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сонғы жаңалықтар"])}
  },
  "criteria": {
    "teacher-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мұғалім таңдалмаған"])},
    "success-saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтті сақталды"])},
    "lesson-control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сабақты бақылау"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таңдау"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
    "select-teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бағалау үшін мұғалімді таңдаңыз"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кері байланыс"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
    "lesson-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сабақ жоспары"])},
    "select-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күнді таңдаңыз"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өңдеу"])},
    "add-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["добавить поле"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
    "add-lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить урок"])},
    "edit-lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать урок"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "teachers-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher's name"])}
  }
}