export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello!!"])},
  "coming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMING SOON"])},
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
  "cabinet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Area"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shymkent, md. Akzhayik, building 6"])},
  "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow Us"])},
  "read-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To learn more...."])},
  "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
  "video-trans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video broadcast of competitive selection"])},
  "corp_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School corporate mail"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "news": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
    "last-news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last news"])}
  },
  "main-page": {
    "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School mission"])},
    "mission-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To educate an intellectual, healthy global citizen with high moral values who has mastered modern learning skills."])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About school"])},
    "best-school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The best school for your children"])},
    "about-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazarbayev Intellectual School of Physics and Mathematics in Shymkent opened on February 16, 2013. Akim of the South Kazakhstan region Askar Myrzakhmetov took part in the solemn event. The new school in one of the largest regions of Kazakhstan has become the ninth in the family of Nazarbayev Intellectual Schools and is designed to ensure the qualitative growth of education, the introduction of innovations in the educational process, and the improvement of the qualifications of teaching staff. All conditions are created for this."])},
    "almuni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GRADUATES"])},
    "foreign-graduate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GET INTO A FOREIGN UNIVERSITY"])},
    "local-graduate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTERED A KAZAKHSTAN UNIVERSITY"])},
    "nu-graduate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GET INTO NAZARBAYEV UNIVERSITY"])},
    "meet-teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet our teachers"])}
  },
  "document": {
    "regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regulations"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of upload"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
  },
  "teachers": {
    "best": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The best trainers"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All trainers"])},
    "trainers-cert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certified trainers"])},
    "trainers-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LIST OF TRAINERS"])}
  },
  "live": {
    "fest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VII Festival of Creative Ideas"])},
    "creative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creative ideas"])},
    "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until the end of the registration of applications left:"])},
    "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
    "last-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent events"])}
  },
  "criteria": {
    "teacher-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher not selected"])},
    "success-saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully saved"])},
    "lesson-control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson observation"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "select-teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select teacher to evaluate"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "lesson-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson Plan"])},
    "select-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select date"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "add-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add line"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "add-lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить урок"])},
    "edit-lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать урок"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "teachers-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher's name"])}
  }
}