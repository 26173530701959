export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привет"])},
  "coming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скоро появится"])},
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
  "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выход"])},
  "cabinet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личный кабинет"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["г.Шымкент, мкр. Акжайык, здание 6"])},
  "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следите за нами"])},
  "read-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Узнать больше...."])},
  "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование"])},
  "video-trans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видеотрансляция конкурсного отбора"])},
  "corp_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корпоративная почта школы"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "news": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новости"])},
    "last-news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последние новости"])}
  },
  "main-page": {
    "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миссия школы"])},
    "mission-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Воспитать интеллектуального, высоконравственного, физически развитого гражданина мира, владеющего современными образовательными навыками."])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О школе"])},
    "best-school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лучшая школа для ваших детей"])},
    "about-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назарбаев Интеллектуальная школа физико-математического направления г. Шымкент открылась 16 февраля 2013 года. В торжественном мероприятии принял участие Аким Южно-Казахстанской области Аскар Мырзахметов.\n\nНовая школа в одном из крупнейших регионов Казахстана стала девятой в семействе Назарбаев Интеллектуальных школ и призвана обеспечить качественный рост образования, внедрение инноваций в учебный процесс, повышение квалификации педагогических кадров. Для этого здесь созданы все условия."])},
    "almuni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВЫПУСКНИКИ"])},
    "foreign-graduate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПОСТУПИЛИ В ЗАРУБЕЖНЫЙ ВУЗ"])},
    "local-graduate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПОСТУПИЛИ В КАЗАХСТАНСКИЙ ВУЗ"])},
    "nu-graduate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПОСТУПИЛИ В НАЗАРБАЕВ УНИВЕРСИТЕТ"])},
    "meet-teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Встречайте Наших учителей"])}
  },
  "document": {
    "regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нормативные документы"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата загрузки"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действие"])}
  },
  "teachers": {
    "best": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лучшие тренера"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все тренера"])},
    "trainers-cert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сертифицированные тренеры"])},
    "trainers-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СПИСОК ТРЕНЕРОВ"])}
  },
  "live": {
    "fest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VII Фестиваль творческих идей"])},
    "creative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Креативный полет"])},
    "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До завершения регистрация  заявок осталось:"])},
    "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["События"])},
    "last-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последние события"])}
  },
  "criteria": {
    "teacher-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учитель не выбран"])},
    "success-saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно сохранен"])},
    "lesson-control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наблюдение урока"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
    "select-teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать оцениваемого учителя"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратная связь"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
    "lesson-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["План урока"])},
    "select-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать дату"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
    "add-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["добавить поле"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
    "add-lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить урок"])},
    "edit-lesson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать урок"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "teachers-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher's name"])}
  }
}