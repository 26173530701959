import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import style from '@/assets/css/style.css'
import swiper from '@/assets/css/swiper.css'
import bscss from "bootstrap/dist/css/bootstrap.min.css"
import bs from "bootstrap"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faInstagram, faLinkedin, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import i18n from './i18n'
import "./axios";


library.add(fas)
library.add(faFacebook)
library.add(faFacebookF)
library.add(faInstagram)
library.add(faLinkedin)
library.add(faTwitter)

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    icons: {
        defaultSet: 'mdi',
    },
    components,
    directives,
})


createApp(App).use(i18n)
    .component('fawesome', FontAwesomeIcon)
    .use(bscss)
    .use(bs)
    .use(style)
    .use(swiper)
    .use(store)
    .use(router)
    .use(vuetify)
    .mount('#app')
