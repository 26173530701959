export default {
  state: {
    main:
      [
        {
          title:{
            kz:"Жазғы мектеп",
            ru:"Летняя школа",
            en:"Summer school"
          },
          description:{
            kz:"Жазғы демалыс пен әртүрлі пәндік бағыттағы белсенді сабақтарды біріктіретін жазғы мектеп лагерінің бір түрі. Әдетте, мұндай мектептерді университеттердің оқытушылары мен студенттері, ғалымдар - энтузиастар жүргізеді. Жазғы мектептер ғылыми қоғамдастықты үлгі етуге және дарынды оқушыларды зерттеу жұмыстарына тартуға арналған. Жазғы мектептің мақсаты – жобалық, шығармашылық және ойын әрекеттері арқылы үш тілде тілдік және академиялық дағдыларды дамыту. Жазғы мектептің мақсаты – оқушылардың сөздік қорын байыту, студенттерді ағылшын тілінде пәндерді оқуға дайындау, келесі сыныпта оқуға қажетті дағдыларды (академиялық жазу, жобалық жұмыс дағдыларын және т.б.) дамыту.",
            ru:"Разновидность летнего школьного лагеря, в котором сочетаются летний отдых и активные занятия в различных предметных областях. Как правило, проводят такие школы учителя и студенты ВУЗов, учёные - энтузиасты. Летние школы призваны моделировать научное сообщество и привлекать одарённых школьников к исследовательской работе. Целью Летней школы является развитие языковых и академических навыков на трех языках через проектную, творческую и игровую деятельность. Задачами летней школы является обогащение словарного запаса учащихся, подготовка учащихся к изучению предметов на английском языке, развитие навыков, необходимых для обучения в следующем классе (академическое письмо, навыки проектной работы и т.д.)",
            en:"A kind of summer school camp, which combines summer holidays and active classes in various subject areas. As a rule, such schools are conducted by teachers and students of universities, scientists - enthusiasts. Summer schools are designed to model the scientific community and involve gifted students in research work. The goal of the Summer School is to develop language and academic skills in three languages through project, creative and play activities. The objectives of the summer school are to enrich students' vocabulary, prepare students to study subjects in English, develop the skills necessary for studying in the next grade (academic writing, project work skills, etc.)",
          }
        },
        {
          title:{
            kz:"Медициналық қызмет",
            ru:"Медицинская служба",
            en:"Medical service"
          },
          description:{
            kz:"Мектептің медициналық блогын дәрігерлік кабинет, емдеу кабинеттері, массаж кабинеттері және оқшаулау кабинеттері құрайды. Медициналық кабинеттер мемлекеттік лицензия негізінде жұмыс істейді.",
            ru:"Медицинский блок школы представлен кабинетом врача, процедурными кабинетами, кабинетами массажа и изоляторами. Медицинские кабинеты функционируют на основании государственной лицензии.",
            en:"The medical block of the school is represented by a doctor's office, treatment rooms, massage rooms and isolation rooms. Medical offices operate on the basis of a state license.",
          },
          image:{
            description:{
              kz:"Мектептің медициналық қызметкерлеріне педиатр, тамақтану бойынша медбике, жатақхана медбикесі және мектеп медбикесі кіреді.",
              ru:"Медицинский штат школы включает врача-педиатра, медицинскую сестру - специалиста по питанию, медсестру общижития и медсестру школы.",
              en:"The school's medical staff includes a pediatrician, a nutritional nurse, a dormitory nurse, and a school nurse.",
            },
            pic:[
              "team/med/abdikulova_g.jpg",
              "team/med/bibolova_b.jpg",
              "team/med/kadirbayeva_a.jpg",
              "team/med/muhangalieva_m.jpg",
              "team/med/sadikova_b.jpg",
            ]
          }
        },
        {
          title:{
            kz:"Кітапхана туралы",
            ru:"О Библиотеке",
            en:"About the Library"
          },
          description:{
            kz:"Шымкент қаласындағы ФМН Назарбаев Зияткерлік мектебінің кітапханасы мектеп ашылған күннен бастап, 2013 жылдың 16 ақпанынан бастап жұмыс істейді. Кітапхана бүгінде оқу және ғылыми үдерісті әдебиеттермен қамтамасыз ететін мектептің маңызды құрылымдық бөлімшесі, сонымен қатар мектеп оқушылары мен ұстаздар арасындағы білімді тарату, рухани және интеллектуалдық байланыс орталығы болып табылады.",
            ru:"Библиотека Назарбаев Интеллектуальной школы ФМН г.Шымкент существует с момента открытия школы, с 16 февраля 2013 года. Библиотека сегодня является важным структурным подразделением школы, обеспечивающим литературой образовательный и научный процесс, а также является центром распространения знаний, духовного и интеллектуального общения школьников и учителей.",
            en:"The library of the Nazarbayev Intellectual School of FMN in Shymkent has existed since the opening of the school, on February 16, 2013. The library today is an important structural unit of the school, providing literature for the educational and scientific process, and is also a center for the dissemination of knowledge, spiritual and intellectual communication between schoolchildren and teachers.",
          },
          pic:"library/book.jpg"
        },
        {
          title:{
            kz:"Мектеп жатақханасы",
            ru:"Общежитие школы",
            en:"School dormitory"
          },
          description:{
            kz:"<p>Жатақхана қызметі әрбір студентке қолайлы жағдай жасауға, олардың табысты білім алуы үшін өмірі мен денсаулығының қауіпсіздігін қамтамасыз етуге бағытталған.</p>" +
                "<p>Жатақханадағы тәрбие жұмысы мектептің мақсат-міндеттеріне, миссиясы мен тәрбиелік құндылықтарына сәйкес, «Мәңгілік Ел» құндылықтары мен «Рухани жаңғыру» рухани жаңғыру бағдарламасы негізінде жүзеге асырылады. оқушылардың ой-пікірлері, тәрбие жұмысы тәрбиенің негізгі жеті бағытын жүзеге асыруға, жан-жақты дамыған, рухани-адамгершілік тұлғаны қалыптастыруда және азаматты тәрбиелеуде оқу-тәрбие процесінің тиімділігін арттыруға бағытталған. Сыныптан тыс жұмыстар келесі негізгі бағыттар бойынша жүзеге асырылады: рухани-адамгершілік тәрбие, патриоттық тәрбие, интеллектуалдық, көпмәдениетті, көркем-эстетикалық, физикалық және психологиялық даму, көшбасшылық қасиеттерді дамыту.</p>",
            ru:"<p>Деятельность общежития направлена на создание благоприятных условий для каждого учащегося,  обеспечение безопасности жизни и здоровья  для их  успешного обучения.</p>" +
                "<p>Воспитательная работа в общежитии осуществляется в соответствии с целями и задачами школы, миссией и образовательными ценностями, на основе ценностей «Мәңгілік Ел» и программы духовной модернизации «Рухани жаңғыру», с учетом мнений учащихся воспитательная работа направлена на реализацию основных семи направлений воспитания, на повышение эффективности учебно-воспитательного процесса в формировании гармонично развитой, духовно-нравственной личности и воспитании гражданина. Внеурочная деятельность реализуется по следующим основным направлениям: духовно-нравственное воспитание, патриотическое воспитание, интеллектуальное, поликультурное, художественно-эстетическое, физическое и психологическое развитие, развитие лидерских качеств.</p>",
            en:"<p>The dormitory's activity is aimed at creating favorable conditions for each student, ensuring their safety and health in order for them to succeed in their education.</p>" +
                "<p>The educational work in the dormitory is carried out in accordance with the school's goals and objectives, mission, and educational values, which are based on the values of \"Mangilik El\" and the spiritual modernization program \"Rukhani Zhangyru.\" Taking into account student feedback, educational work aims to implement the main seven areas of education: increase the effectiveness of the educational process in the formation of a harmoniously developed, spiritual and moral personality, and citizen education. Extracurricular activities are implemented in the following areas: spiritual and moral education, patriotic education, intellectual, multicultural, artistic and aesthetic development, physical and psychological development, and leadership development.</p>",
          },
          pic:"dormitory/dji.jpg"
        },
      ],
    activities: [
      {
        'kz':{
          title: "Шығармашылық ұшу",
          description: "VII Шығармашылық идеялар фестивалі",
          url:"creative",
          urlText:"Тіркеу",
          image:"/img/live/creative.png"
        },
        'ru':{
          title: "Креативный полет",
          description: "VII Фестиваль творческих идей",
          url:"creative",
          urlText:"Регистрация",
          image:"/img/live/creative.png"
        },
        'en':{
          title: "Creative ideas",
          description: "VII Festival of Creative Ideas",
          url:"creative",
          urlText:"Registration",
          image:"/img/live/creative.png"
        }
      },

    ]
  },
  getters: {
    getMain (state) {
      return state.main
    },
    getActivities (state){
      return state.activities
    }
  },
  mutations: {

  },
  actions: {

  },
  namespaced: true
}
