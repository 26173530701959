export default {
  state: {

    documentData: [
      {
        childs: [
          {
            name: {ru:'Правила внутреннего распорядка для учащихся Назарбаев Интеллектуальных школ',kz:'Nazarbaev Ziatkerlık mektepterınıñ oquşylaryna arnalğan ışkı tärtıp erejelerı',en:'Internal regulations for students of Nazarbayev Intellectual Schools'},
            created_date: {ru:'04.11.2022',kz:'04.11.2022',en:'04.11.2022'},
            url: "https://mailnisedu-my.sharepoint.com/:b:/g/personal/abzal_amantay_fmsh_nis_edu_kz/Ee2Mo6eZavZFh3EL5zu3MFoB56EriLtB9o9MVOqa_ctXUg?e=qI0Ttm"
          },
          {
            name: {ru:'Правила общежития',kz:'Жатақхана ережелері',en:'Dormitory rules'},
            created_date: {ru:'01.06.2022',kz:'01.06.2022',en:'01.06.2022'},
            url: "https://mailnisedu-my.sharepoint.com/:b:/g/personal/abzal_amantay_fmsh_nis_edu_kz/Ee2Mo6eZavZFh3EL5zu3MFoB56EriLtB9o9MVOqa_ctXUg?e=qI0Ttm"
          },
          {
            name: {ru:'Мониторинг по ПРИШ за 3 квартал',kz:'Мониторинг по ПРИШ за 3 квартал',en:'Мониторинг по ПРИШ за 3 квартал'},
            created_date: {ru:'21.11.2022',kz:'21.11.2022',en:'21.11.2022'},
            url: "https://mailnisedu-my.sharepoint.com/:w:/g/personal/abzal_amantay_fmsh_nis_edu_kz/EUqQzwvJaDVPj-K8IlEdimgBnCQ_473lbtm11e2kB4wh_w?e=TPGoed"
          },
          {
            name: {ru:'Отчет учебно-воспитательного процесса НИШ ФМН г. Шымкент за 2021-2022 учебный год',kz:'Отчет учебно-воспитательного процесса НИШ ФМН г. Шымкент за 2021-2022 учебный год',en:'Отчет учебно-воспитательного процесса НИШ ФМН г. Шымкент за 2021-2022 учебный год'},
            created_date: {ru:'16.01.2023',kz:'16.01.2023',en:'16.01.2023'},
            url: "https://mailnisedu-my.sharepoint.com/:b:/g/personal/abzal_amantay_fmsh_nis_edu_kz/ESdXS3NoyXNBnBn1UkA4lpkBbdtT-FfmQSghSEvYpsDySA?e=D6w3ib"
          },
          {
            name: {ru:'Отчет учебно-воспитательного процесса НИШ ФМН г. Шымкент за 2019-2020 учебный год',kz:'Отчет учебно-воспитательного процесса НИШ ФМН г. Шымкент за 2019-2020 учебный год',en:'Отчет учебно-воспитательного процесса НИШ ФМН г. Шымкент за 2019-2020 учебный год'},
            created_date: {ru:'16.01.2023',kz:'16.01.2023',en:'16.01.2023'},
            url: "https://mailnisedu-my.sharepoint.com/:b:/g/personal/abzal_amantay_fmsh_nis_edu_kz/Edw75LezGVpGiUbBPyCUQ1ABy2baFCfO5ait60PA0XC5CQ?e=3r1qes"
          },
          {
            name: {ru:'Отчет учебно -воспитательного процесса НИШ ФМН г. Шымкент за 2020-2021 учебный год',kz:'Отчет учебно -воспитательного процесса НИШ ФМН г. Шымкент за 2020-2021 учебный год',en:'Отчет учебно -воспитательного процесса НИШ ФМН г. Шымкент за 2020-2021 учебный год'},
            created_date: {ru:'16.01.2023',kz:'16.01.2023',en:'16.01.2023'},
            url: "https://mailnisedu-my.sharepoint.com/:b:/g/personal/abzal_amantay_fmsh_nis_edu_kz/EToS19rtTpVMgABH5agaGZ8BwoTSe1mXTbxy4VeeJLXu4A?e=dhst4n"
          }

        ]
      },
    ]
  },
  getters: {
    getDocumentData (state) {
      return state.documentData
    }
  },
  mutations: {

  },
  actions: {

  },
  namespaced: true
}
