<template>
    <!-- About Start -->
    <div class="container-fluid py-5">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <img class="img-fluid rounded mb-5 mb-lg-0" :src="require('@/assets/img/about/about.png')" alt="">
                </div>
                <div class="col-lg-7">
                    <p class="section-title pr-5"><span class="pr-2">Узнайте о нас</span></p>
                    <h1 class="mb-4">Немного истории школы</h1>
                    <p>Назарбаев Интеллектуальная школа физико-математического направления г. Шымкент открылась 16 февраля 2013 года. В торжественном мероприятии принял участие Аким Южно-Казахстанской области Аскар Мырзахметов.</p>
                    <p>Новая школа в одном из крупнейших регионов Казахстана стала девятой в семействе Назарбаев Интеллектуальных школ и призвана обеспечить качественный рост образования, внедрение инноваций в учебный процесс, повышение квалификации педагогических кадров. Для этого здесь созданы все условия.</p>
<!--                    <div class="row pt-2 pb-4">
                        <div class="col-6 col-md-4">
                            <img class="img-fluid rounded" :src="require('@/assets/img/about/about-2.jpg')" alt="">
                        </div>
                        <div class="col-6 col-md-8">
                            <ul class="list-inline m-0">
                                <li class="py-2 border-top border-bottom"><i class="fa fa-check text-primary mr-3"></i>Labore eos amet dolor amet diam</li>
                                <li class="py-2 border-bottom"><i class="fa fa-check text-primary mr-3"></i>Etsea et sit dolor amet ipsum</li>
                                <li class="py-2 border-bottom"><i class="fa fa-check text-primary mr-3"></i>Diam dolor diam elitripsum vero.</li>
                            </ul>
                        </div>
                    </div>-->
                    <!--<a href="" class="btn btn-primary mt-2 py-2 px-4">Learn More</a>-->
                </div>
            </div>
        </div>
    </div>
    <!-- About End -->
</template>

<script>
    export default {
        name: "AboutSchool"
    }
</script>

<style scoped>

</style>